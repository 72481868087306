import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { toWords, toWordsOrdinal } from 'number-to-words';
import { Tafgeet } from "tafgeet-arabic";
import Tabletva from '../TableTVA/Tabletva';
import { NumberToLetter } from 'convertir-nombre-lettre';

import fontkit from '@react-pdf/fontkit';

export default function Facture({ entetfacture, societe, inputList, devise, t, listTva, client }) {
    const image_url = process.env.REACT_APP_API_URL;
    const [total, setotal] = useState({ integerPart: '', decimalPart: '' });
    Font.register({
        family: 'Arial',
        src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
        fontkit
    });
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            padding: 30,
            fontSize: '5px',
        },
        section: {
            marginBottom: 0,
            flexDirection: 'row',
        },
        sectionligne: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '9px',
        },
        logo_clediss: {
            width: 95,
            height: 95,
        },
        colone: {
            width: '50%',
        },
        colone_info: {
            width: '30%',
        },
        colone_info_titre: {
            width: '20%',
        },
        colone_info_espace: {
            width: '20%',
        },
        colone_pieds: {
            width: '25%', // Adjusted to fit the new width of pied2
        },
        colone_pieds_1: {
            width: '10%', // Adjusted as needed
        },
        colone_devise: {
            width: '20%',
        },

        colone_devise_chiffre: {
            width: '40%',
        },
        coloneTab: {
            width: '14%',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        row: {
            marginBottom: 3,
            fontSize: 10,
        },
        marginTop: {
            marginTop: 20,
        },
        logo: {
            width: 95,
            height: 35,
        },
        horizontalLine: {
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            marginBottom: 3,
        },
        pied1: {
            marginTop: '10px',
            width: '60%', // Adjusted to fit within pied2 width
            paddingLeft: '30px',
            flexDirection: 'row',
        },
        pied2: {
            marginTop: '10px',
            width: '50%', // Adjusted to span entire A4 width
        },
        arabicText: {
            direction: 'rtl',
        },
    });
    
    useEffect(() => {
        if (entetfacture.net_a_payer) {
            const integerPart = Math.floor(parseFloat(entetfacture.net_a_payer)) ||0;
            const decimalPart = Math.round((parseFloat(entetfacture.net_a_payer) - integerPart) * 100) ||0; // Get the decimal part without decimal point
            const lang = localStorage.getItem('i18nextLng');
            if (lang === 'fr') {
               
                const data = {
                    integerPart: NumberToLetter(integerPart),
                    decimalPart: NumberToLetter(decimalPart),
                };
                
                setotal(data);

            }
            else if (lang === 'en') {
                const data = {
                    integerPart: toWords(integerPart),
                    decimalPart: toWords(decimalPart),
                };
                setotal(data);

            } else if (lang === 'ar') {
                const data = {
                    integerPart: new Tafgeet(parseFloat(integerPart), 'TND').parse(),
                    decimalPart: new Tafgeet(parseFloat(decimalPart), 'TND').parse(),
                };
                setotal(data);

            }

        }




    }, [entetfacture]);
    const [newlisttva, setNewlisttva] = useState([]);

    useEffect(() => {
        const updatedList = [];

        listTva.forEach(element => {
            const findIndex = updatedList.findIndex(item => item.tauxtva === element.tva);
            if (findIndex !== -1) {
                updatedList[findIndex].total_montant += parseFloat(element.prix);
                updatedList[findIndex].count += 1;
            } else {
                updatedList.push({
                    tauxtva: element.tva,
                    total_montant: parseFloat(element.prix),
                    count: 1
                });
            }
        });

        setNewlisttva(updatedList);
    }, [listTva]);


    return (
        <Document>
            <Page size="A4" style={styles.page}>

                <View style={styles.section}>

                    <View style={styles.colone}>
                        <View style={styles.row}>

                            <Image style={styles.logo_clediss} src={image_url + societe.logo} />
                        </View>
                        <View style={styles.row}>
                            {societe?.imageente !== null && <Image style={styles.logo} src={image_url + societe?.imageente} />}

                        </View>

                    </View>

                    <View style={styles.colone}>

                        <Text style={styles.row}><strong>Société {societe.nom}</strong></Text>
                        <Text style={styles.row}> {societe.matricule_fiscal}</Text>
                        <Text style={styles.row}> {societe.website_url}</Text>
                        <Text style={styles.row}>{societe.mobile}</Text>
                        <Text style={styles.row}> {societe.email}</Text>
                    </View>
                    <View style={styles.colone}  >
                        <Text style={styles.row}> <p style={{ marginTop: "500px" }}>{societe.adresse}</p></Text>

                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.row}><strong>{entetfacture.type.toUpperCase()}</strong></Text>

                </View>

                <View style={styles.section}>
                    <View style={styles.colone_info_titre}>
                        <Text style={styles.row}>Numéro :</Text>
                        <Text style={styles.row}>Date :</Text>
                        <Text style={styles.row}>Date d'echeance:</Text>

                        {entetfacture.type !== 'commande' && (<Text style={styles.row}>Num commande :</Text>)}

                    </View>
                    <View style={styles.colone_info}>
                        <Text style={styles.row}>{entetfacture.facture_code}</Text>
                        <Text style={styles.row}>{entetfacture.date}</Text>
                        <Text style={styles.row}>{entetfacture.date_echeance}</Text>

                        {entetfacture.type !== 'commande' && (<Text style={styles.row}>{entetfacture.code_commande}</Text>)}

                    </View>


                    <View style={styles.colone_info}>
                        <Text style={styles.row}>{entetfacture.client_nom}</Text>
                        <Text style={styles.row}>{entetfacture.client_adresse_facturation}</Text>
                        <Text style={styles.row}>{entetfacture.client_mf}</Text>

                        <Text style={styles.row}>{entetfacture.client_email}</Text>
                        <Text style={styles.row}>{entetfacture.client_mobile}</Text>



                    </View>
                </View>


                <View style={styles.marginTop} />
                <View style={styles.sectionligne}>

                    <View style={styles.colone_info}>
                        <Text style={styles.row}>Description</Text>
                    </View>
                    <View style={styles.colone_info_espace}>
                        <Text style={styles.row}></Text>
                    </View>
                    <View style={styles.coloneTab}>
                        <Text style={styles.row}>PU.HT</Text>
                    </View>
                    <View style={styles.coloneTab}>
                        <Text style={styles.row}>QTE</Text>
                    </View>

                    <View style={styles.coloneTab}>
                        <Text style={styles.row}>R.L+R.P</Text>
                    </View>
                  
                    <View style={styles.coloneTab}>
                        <Text style={styles.row}>Total</Text>
                    </View>
                </View>
                <View style={styles.horizontalLine} />
                {inputList.map((item, index) => (
                    <View key={index}>
                        <View style={styles.sectionligne} >
                            <View style={styles.colone_info}>
                                <Text style={styles.row}>{item.libelleProduit}</Text>
                            </View>
                            <View style={styles.colone_info_espace}>
                                <Text style={styles.row}></Text>
                            </View>
                            <View style={styles.coloneTab}>
                                <Text style={styles.row}>{item.pu_ht}</Text>
                            </View>
                            <View style={styles.coloneTab}>
                                <Text style={styles.row}>{item.quantite}</Text>
                            </View>

                            <View style={styles.coloneTab}>
                                <Text style={styles.row}>{parseInt((1-(item.total_net_ht/item.total_ht))*100)}%</Text>
                            </View>
                           
                            <View style={styles.coloneTab}>
                                <Text style={styles.row}>{item.total_net_ht}</Text>
                            </View>
                        </View>
                        <View style={styles.horizontalLine} />

                    </View>
                ))}

                <View  style={styles.section}>
                    <View style={styles.pied2}>
                        <View style={styles.section}>
                            <View style={styles.colone_pieds}>
                                <Text style={styles.row}></Text>
                            </View>
                            <View style={styles.colone_pieds}>
                                <Text style={styles.row}>Taux tva</Text>
                            </View>
                            <View style={styles.colone_pieds}>
                                <Text style={styles.row}>base</Text>
                            </View>
                            <View style={styles.colone_pieds}>
                                <Text style={styles.row}>Montant</Text>
                            </View>


                        </View>
                        <View style={styles.horizontalLine} />
                        {newlisttva.map((item, index) => (
                            <View key={index}>
                                <View style={styles.sectionligne} >
                                    <View style={styles.colone_pieds}>
                                        <Text style={styles.row}>{item.count}</Text>
                                    </View>
                                    <View style={styles.colone_pieds}>
                                        <Text style={styles.row}>{item.tauxtva}%</Text>
                                    </View>
                                    <View style={styles.colone_pieds}>
                                        <Text style={styles.row}>{item.total_montant.toFixed(3)}</Text>
                                    </View>
                                    <View style={styles.colone_pieds}>
                                        <Text style={styles.row}>{((item.total_montant.toFixed(3)) * (item.tauxtva / 100)).toFixed(3)}</Text>
                                    </View>

                                </View>
                                <View style={styles.horizontalLine} />

                            </View>
                        ))}
                    </View>
                    <View style={styles.pied1}>
                        <View style={styles.colone_devise_chiffre}>
                            <Text style={styles.row}><strong>Total Brut</strong></Text>
                            <Text style={styles.row}> <strong>Net HT</strong></Text>
                            <Text style={styles.row}> <strong>Montant remise </strong></Text>
                            <Text style={styles.row}> <strong>Montant tva</strong></Text>
                            <Text style={styles.row}><strong>Total TTC</strong></Text>
                            {entetfacture.type === 'facture' && societe &&  <Text style={styles.row}><strong>Timbre  Fiscale</strong></Text>}
                            <Text style={styles.row}><strong>Net a payer</strong></Text>

                        </View>
                        <View style={styles.colone_devise_chiffre}  >
                            <Text style={styles.row}><strong>{entetfacture.montant_total_ht}</strong></Text>
                            <Text style={styles.row}> <strong>{entetfacture.net_a_payer_ht}</strong></Text>
                            <Text style={styles.row}> <strong>{entetfacture.remise_total}</strong></Text>
                            <Text style={styles.row}> <strong>{entetfacture.montant_total_tva}</strong></Text>
                            <Text style={styles.row}><strong>{entetfacture.montant_total_ttc}</strong></Text>
                            {entetfacture.type === 'facture' && societe && <Text style={styles.row}><strong>{societe.timbre_fiscale.toFixed(devise?.devision||3)}</strong></Text>}

                            <Text style={styles.row}><strong>{entetfacture.net_a_payer}</strong></Text>
                        </View>
                        <View style={styles.colone_devise}  >
                            <Text style={styles.row}><strong>{devise?.code_devise}</strong></Text>
                            <Text style={styles.row}> <strong>{devise?.code_devise}</strong></Text>
                            <Text style={styles.row}> <strong>{devise?.code_devise}</strong></Text>
                            <Text style={styles.row}><strong>{devise?.code_devise}</strong></Text>
                            <Text style={styles.row}><strong>{devise?.code_devise}</strong></Text>
                            {entetfacture.type === 'facture' && societe && <Text style={styles.row}><strong>{devise?.code_devise}</strong></Text>}
                            <Text style={styles.row}><strong>{devise?.code_devise}</strong></Text>

                        </View>
                    </View>

                </View>

                <View style={styles.section}>
                    <Text style={styles.row}>{t('ARRÊTE LA PRÉSENTE FACTURE A LA SOMME DE ')}{total.integerPart.toUpperCase()}{t(' ET ')}{devise?.libelle.toUpperCase()}{' '}{total.decimalPart.toUpperCase()}{' '} {devise?.fraction?.toUpperCase()}</Text>


                </View>
                <View style={styles.section}>
                    <Text style={styles.row}> <strong>Note</strong></Text>
                    <Text style={styles.row}>{entetfacture.commentaire}</Text>

                </View>
                <View style={styles.sectionligne}>
                    <Text style={styles.row}> {societe.ligne_facture_1}</Text>


                </View>
                <View style={styles.sectionligne}>
                    <Text style={styles.row}> {societe.ligne_facture_2}</Text>


                </View><View style={styles.sectionligne}>
                    <Text style={styles.row}> {societe.ligne_facture_3}</Text>


                </View><View style={styles.sectionligne}>
                    <Text style={styles.row}> {societe.ligne_facture_4}</Text>


                </View>

                <View style={styles.section}>

                    <View style={styles.colone}>
                       
                        <View style={styles.row}>
                            {societe?.imagepied !== null && <Image  src={image_url + societe?.imagepied} />}

                        </View>

                    </View>
              </View>
            </Page>
        </Document>
    );
}
