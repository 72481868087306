import React, { useState } from 'react'
import { IoIosCloseCircle } from "react-icons/io";
import { IoPencil } from "react-icons/io5";
import { toast } from 'react-toastify';
import moment from "moment";
import { handleKeyPress } from '../../../Utils/InputUtils';
import { IoSaveOutline } from "react-icons/io5";
import { arondisnumber, arrondi_entier, calcul_total_rub, float_decimale, formatNChiffres, nombreDeMoisEntreDeuxDates } from '../../../Utils/helper';
export default function Info_paie_employe({ setligne_supprimer, ligne_supprimer, t, save, change, setchange, list_rubriques, list_avantages, setlist_avantages, setlist_rubriques, data_map, click, setclick, setshow, data_change, setdata_map, setdata_change, CalculSalaire, calculbrut, param_paie_active, prime }) {

    const [add_rubriques, setadd_rubriques] = useState({
        user_code: '',
        code_generated: localStorage.getItem('code_generated'),
        rub_code: '',
        permanent: 1,
        date_debut: null,
        date_fin: null,
        exercice: '',
        valeur: 0,


    })
    const [add_av_fiscaux, setadd_av_fiscaux] = useState({
        user_code: '',
        code_generated: localStorage.getItem('code_generated'),
        rub_code: '',
        permanent: 1,
        date_debut: null,
        date_fin: null,
        exercice: '',
        valeur: 0,


    })
    const [add_avantage, setadd_avantage] = useState({

        user_code: '',
        code_generated: localStorage.getItem('code_generated'),
        avantage_code: '',
        exercice: '',
        permanent: 1,
        date_debut: null,
        date_fin: null,
        valeur: 0
    })
    const [regime_horaire, setregime_horaire] = useState(false)
    const [add_retenue, setadd_retenue] = useState({
        user_code: '',
        code_generated: localStorage.getItem('code_generated'),
        rub_code: '',
        permanent: 1,
        date_debut: null,
        date_fin: null,
        exercice: '',
        valeur_annuel: 0,
        valeur_mensuel: 0,



    })
    const handleChangedata = (name, value, index) => {

        const updatedelement = [...data_map]
        const changes_list = [...data_change]
        const v = float_decimale(value) || 0
        const changes_ab_index = changes_list.findIndex(item => item.userid === updatedelement[index].user_id && item.name === name)
        if (changes_ab_index !== -1) {
            changes_list[changes_ab_index].newvalue = value;

        } else {
            const datachange = { userid: updatedelement[index].user_id, name: name, oldvalue: updatedelement[index][name], newvalue: value }
            changes_list.push(datachange)

        }


        if (name === 'salaire_base') {
            
            updatedelement[index][name] = parseFloat(value)||0;
            let salaire_journee = (parseFloat(updatedelement[index].salaire_base) / updatedelement[index].nbrjtravaille)
            const salaire_calculer = CalculSalaire(updatedelement[index].total, salaire_journee);
            updatedelement[index].salaire_retenue = float_decimale((salaire_calculer.salaire_journee * updatedelement[index].total_Ab).toFixed(3));
            updatedelement[index].salaire_journee = salaire_calculer.salaire_journee
            updatedelement[index].salaire_base_calculer = salaire_calculer.salaire_base_calculer
        }
        if (name === 'total_heure_present') {
            const total_present = value / data_map[click.index].Nbr_h_jr
            name = "total_present"
            value = total_present

        }
        if (name === 'total_present') {
            const salaire_base = updatedelement[index].salaire_base

            updatedelement[index].total = (updatedelement[index].total - updatedelement[index].total_present) + v;
            const new_ab = updatedelement[index].total_Ab + (updatedelement[index].total_present - v)
            updatedelement[index].salaire_p = v * updatedelement[index].salaire_journee
            const changes_ab_index = changes_list.findIndex(item => item.userid === updatedelement[index].user_id && item.name === 'Njr_Ab')
            if (changes_ab_index !== -1) {
                changes_list[changes_ab_index].newvalue = new_ab;

            } else {
                const datachange = { userid: updatedelement[index].user_id, name: 'Njr_Ab', oldvalue: updatedelement[index].total_Ab, newvalue: new_ab }
                changes_list.push(datachange)

            }



            updatedelement[index].total_Ab = new_ab

            updatedelement[index].total_present = float_decimale(value);


            const salaire_calculer = CalculSalaire(updatedelement[index].total, updatedelement[index].salaire_journee);

            updatedelement[index].salaire_retenue = float_decimale((salaire_calculer.salaire_journee * updatedelement[index].total_Ab).toFixed(3));
            // updatedelement[index].salaire_journee = salaire_calculer.salaire_journee
            updatedelement[index].salaire_base_calculer = salaire_calculer.salaire_base_calculer



            updatedelement[index].salaire_base_calculer = arondisnumber(updatedelement[index].salaire_base_calculer + updatedelement[index].total_supp_t1 + updatedelement[index].total_supp_t2, 8)

            updatedelement[index].total_rub_cnss = updatedelement[index].rubs_indemnite?.reduce((acc, curr) => {
                if (curr.cnss === 1) {
                    if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                        return acc + (curr.valeur_annuel / updatedelement[index].nb_s);
                    } else {
                        if (curr.presence === 1) {

                            return acc + curr.valeur_mensuel * updatedelement[index].total;

                        } else {
                            return acc + curr.valeur_mensuel;

                        }
                    }


                }
                else {
                    return acc + 0;

                }

            }, 0);

            //  updatedelement[index].commission = updatedelement[index].total_av + updatedelement[index].total_rub_cnss

            updatedelement[index] = calculbrut(updatedelement[index])
        }
        if (name === 'total_conge') {
            const difconge = (updatedelement[index].total_conge || 0) - float_decimale(value)

            updatedelement[index].soldeconge = updatedelement[index].soldeconge + difconge
            updatedelement[index].difconge = difconge
            //  alert( updatedelement[index].difconge)
            if (updatedelement[index].soldeconge < 0) {

                toast.warning('depasse solde conge ')



            }
            // const difconge = (updatedelement[index].total_conge+updatedelement[index].soldeconge)-value
            // alert(difconge)

            const salaire_base = updatedelement[index].salaire_base

            const new_presence = updatedelement[index].total_present

            /* const changes_ab_index = changes_list.findIndex(item => item.userid === updatedelement[index].user_id && item.name === 'Njr_Ab')
             if (changes_ab_index !== -1) {
                 changes_list[changes_ab_index].newvalue = new_ab;
 
             } else {
                 const datachange = { userid: updatedelement[index].user_id, name: 'Njr_Ab', oldvalue: updatedelement[index].total_Ab, newvalue: new_ab }
                 changes_list.push(datachange)
 
             }*/


            updatedelement[index].salaire_c = formatNChiffres(v * updatedelement[index].salaire_journee, 3)
            //  updatedelement[index].total_Ab = new_ab

            updatedelement[index].total_conge = float_decimale(value)
            updatedelement[index].total = new_presence + updatedelement[index].total_conge
            updatedelement[index].total_present = new_presence
            if (updatedelement[index].total > updatedelement[index].nbrjtravaille) {

                const diffcorrection = updatedelement[index].total - updatedelement[index].nbrjtravaille

                updatedelement[index].total_present = updatedelement[index].total_present - diffcorrection
                updatedelement[index].total = updatedelement[index].total_present + updatedelement[index].total_conge

            }
            const salaire_calculer = CalculSalaire(updatedelement[index].total, updatedelement[index].salaire_journee);
            updatedelement[index].salaire_retenue = salaire_calculer.salaire_journee * updatedelement[index].total_Ab;
            updatedelement[index].salaire_journee = salaire_calculer.salaire_journee
            updatedelement[index].salaire_base_calculer = salaire_calculer.salaire_base_calculer
            updatedelement[index] = calculbrut(updatedelement[index])
        }
        if (name === 'Nbr_h_jr') {
            const calcul_heure = updatedelement[index].salaire_base / (parseFloat(value) * updatedelement[index].total)

            updatedelement[index].salaire_heure = arondisnumber(calcul_heure, 3)
            updatedelement[index].salaire_heure_t1 = arondisnumber(calcul_heure, 3) * updatedelement[index].h_sup_t1 || 0
            updatedelement[index].salaire_heure_t2 = arondisnumber(calcul_heure, 3) * updatedelement[index].h_sup_t2 || 0
            updatedelement[index].total_supp_t1 = updatedelement[index].salaire_heure_t1 * updatedelement[index].heure_supp_t1 || 0
            updatedelement[index].total_supp_t2 = updatedelement[index].salaire_heure_t2 * updatedelement[index].heure_supp_t2 || 0


            updatedelement[index][name] = float_decimale(value) || 0
            recalcul_salaire_base(index)

        }
        if (name === 'h_sup_t2') {
            updatedelement[index].salaire_heure_t2 = updatedelement[index].salaire_heure * float_decimale(value) || 0
            updatedelement[index][name] = float_decimale(value) || 0
            updatedelement[index].total_supp_t2 = updatedelement[index].salaire_heure_t2 * updatedelement[index].heure_supp_t2 || 0
            recalcul_salaire_base(index)


        } if (name === 'h_sup_t1') {
            updatedelement[index].salaire_heure_t1 = updatedelement[index].salaire_heure * float_decimale(value) || 0
            updatedelement[index][name] = float_decimale(value) || 0
            updatedelement[index].total_supp_t1 = arondisnumber(updatedelement[index].salaire_heure_t1 * updatedelement[index].heure_supp_t1 || 0, 3)

            recalcul_salaire_base(index)

        } if (name === 'heure_supp_t1') {
            updatedelement[index].total_supp_t1 = arondisnumber(updatedelement[index].salaire_heure_t1 * float_decimale(value) || 0, 3)

            updatedelement[index][name] = float_decimale(value) || 0
            // const hsup1 =updatedelement[index].retenu_salaire_hsup1 === 1 ?updatedelement[index].total_supp_t1 :0
            // updatedelement[index].salaire_base_calculer = updatedelement[index].salaire_base_calculer + hsup1

            recalcul_salaire_base(index)

        }
        if (name === 'heure_supp_t2') {
            updatedelement[index].total_supp_t2 = arondisnumber(updatedelement[index].salaire_heure_t2 * float_decimale(value) || 0, 3)
            updatedelement[index][name] = float_decimale(value) || 0
            recalcul_salaire_base(index)


        } if (name === 'retenu_salaire_hsup1') {
            updatedelement[index].retenu_salaire_hsup1 = updatedelement[index].retenu_salaire_hsup1 === 1 ? 0 : 1
            recalcul_salaire_base(index)
        } if (name === 'retenu_salaire_hsup2') {
            updatedelement[index].retenu_salaire_hsup2 = updatedelement[index].retenu_salaire_hsup2 === 1 ? 0 : 1
            recalcul_salaire_base(index)
        }
        setdata_change(changes_list)

        calculbrut(updatedelement[index])

    }
    const recalcul_salaire_base = (index) => {
        const updatedelement = [...data_map]
        const hsup1 = updatedelement[index].retenu_salaire_hsup1 === 1 ? updatedelement[index].total_supp_t1 : 0

        const hsup2 = updatedelement[index].retenu_salaire_hsup2 === 1 ? updatedelement[index].total_supp_t2 : 0

        updatedelement[index].salaire_base_calculer = arondisnumber(parseFloat(updatedelement[index].salaire_base) + updatedelement[index].total_supp_t1 + updatedelement[index].total_supp_t2, 8)
        const total_jrn = arondisnumber(parseFloat(updatedelement[index].salaire_base) + hsup1 + hsup2, 8)
        updatedelement[index].salaire_journee = total_jrn / updatedelement[index].nbrjtravaille

        setdata_map(updatedelement)

    }


    const handleAddrub = (type, index) => {
        if (type === 'indemnite') {
            const addrubriques = { ...add_rubriques }
            const updated_data = [...data_map]
            addrubriques.exercice = param_paie_active.exercice;
            addrubriques.user_code = data_map[index].user_code
            const findrub = list_rubriques.find(item => item.code === addrubriques.rub_code)

            const obj = { ...addrubriques, ...findrub };

            updated_data[index].rubs_indemnite.push(obj)
            const nb_mois = nombreDeMoisEntreDeuxDates(obj?.date_debut, obj?.date_fin)
            const new_value = calcul_total_rub(obj?.valeur_mensuel, obj.presence, updated_data[index].total, obj.valeur_annuel, nb_mois)
            updated_data[index].commission = updated_data[index].commission + new_value
            updated_data[index].total_rub_cnss = updated_data[index].commission
            setdata_map(updated_data)
            calculbrut(updated_data[index])
            setadd_rubriques({
                user_code: '',
                code_generated: localStorage.getItem('code_generated'),
                rub_code: '',
                permanent: 1,
                date_debut: null,
                date_fin: null,
                exercice: '',
                valeur_mensuel: 0,
                valeur_annuel: 0


            })
        } else if (type === 'avantages') {
            const addrubriques = { ...add_avantage }
            const updated_data = [...data_map]
            addrubriques.exercice = param_paie_active.exercice;
            addrubriques.user_code = data_map[index].user_code
            const findrub = list_avantages.find(item => item.avantage_code === addrubriques.rub_code)

            const obj = { ...addrubriques, ...findrub };

            updated_data[index].avantages.push(obj)
            const nb_mois = nombreDeMoisEntreDeuxDates(obj?.date_debut, obj?.date_fin)
            const new_value = calcul_total_rub(obj?.valeur_mensuel, obj.presence, updated_data[index].total, obj.valeur_annuel, nb_mois)
            updated_data[index].commission = updated_data[index].commission + new_value
            //  updated_data[index].total_rub_cnss = updated_data[index].commission
            setdata_map(updated_data)
            calculbrut(updated_data[index])
            setadd_avantage({
                user_code: '',
                code_generated: localStorage.getItem('code_generated'),
                rub_code: '',
                permanent: 1,
                date_debut: null,
                date_fin: null,
                exercice: '',
                valeur_mensuel: 0,
                valeur_annuel: 0


            })
        } else if (type === 'retenue') {
            const addretenue = { ...add_retenue }
            const updated_data = [...data_map]
            addretenue.exercice = param_paie_active.exercice;
            addretenue.user_code = data_map[index].user_code
            const findrub = list_rubriques.find(item => item.code === addretenue.rub_code)
            const obj = { ...addretenue, ...findrub };

            updated_data[index].rubs_retenue.push(obj)
            const nb_mois = nombreDeMoisEntreDeuxDates(obj?.date_debut, obj?.date_fin)
            const new_value = calcul_total_rub(obj?.valeur_mensuel, obj.presence, updated_data[index].total, obj.valeur_annuel, nb_mois)
            updated_data[index].total_retenue = updated_data[index].total_retenue + new_value

            setdata_map(updated_data)
            calculbrut(updated_data[index])

            setadd_retenue({
                user_code: '',
                code_generated: localStorage.getItem('code_generated'),
                rub_code: '',
                permanent: 1,
                date_debut: null,
                date_fin: null,
                exercice: '',
                valeur_mensuel: 0,
                valeur_annuel: 0



            })
        }
        else {
            const addav_fiscaux = { ...add_av_fiscaux }
            const updated_data = [...data_map]
            addav_fiscaux.exercice = param_paie_active.exercice;
            addav_fiscaux.user_code = data_map[index].user_code
            const findrub = list_rubriques.find(item => item.code === addav_fiscaux.rub_code)

            const obj = { ...addav_fiscaux, ...findrub };

            updated_data[index].avantage_fiscaux.push(obj)

            setdata_map(updated_data)
            setadd_av_fiscaux({
                user_code: '',
                code_generated: localStorage.getItem('code_generated'),
                rub_code: '',
                permanent: 1,
                date_debut: null,
                date_fin: null,
                exercice: '',
                valeur: 0,
            })
        }
    }

    const handleremove = (type, index, code) => {

        const updated_data = [...data_map]

        if (type === 'rub') {


            updated_data[index].rubs_indemnite = updated_data[index].rubs_indemnite.filter(item => item.rub_code !== code)
            /*  const nb_mois = nombreDeMoisEntreDeuxDates(findrub?.date_debut, findrub?.date_fin)
              const new_value = calcul_total_rub(findrub?.valeur_mensuel, findrub.presence, updated_data[index].total, findrub.valeur_annuel, nb_mois)
  
              updated_data[index].commission = arondisnumber(updated_data[index].commission - new_value, 3)*/


        } else if (type === "av") {
            //  const findavantage = updated_data[index].avantages.find(item => item.code_champs === code)

            updated_data[index].avantages = updated_data[index].avantages.filter(item => item.rub_code !== code)
            /* const nb_mois = nombreDeMoisEntreDeuxDates(findavantage?.date_debut, findavantage?.date_fin)
             const new_value = calcul_total_rub(findavantage?.valeur_mensuel, findavantage.presence, updated_data[index].total, findavantage.valeur_annuel, nb_mois)
             updated_data[index].commission = arondisnumber(updated_data[index].commission - new_value, 3)*/

        } else if (type === 'av_fiscaux') {

            updated_data[index].avantage_fiscaux = updated_data[index].avantage_fiscaux.filter(item => item.rub_code !== code)

        }
        else {
            //  const findretenue = updated_data[index].rubs_retenue.find(item => item.code_champs === code)

            updated_data[index].rubs_retenue = updated_data[index].rubs_retenue.filter(item => item.rub_code !== code)
            //  console.log(findretenue)
            /*const nb_mois = nombreDeMoisEntreDeuxDates(findretenue?.date_debut, findretenue?.date_fin)
            const new_value = calcul_total_rub(findretenue?.valeur_mensuel, findretenue.presence, updated_data[index].total, findretenue.valeur_annuel, nb_mois)
          
            updated_data[index].total_retenue = float_decimale(updated_data[index].total_retenue) - new_value*/

        }

        const listdelete = updated_data[index].listdelete ? [...updated_data[index].listdelete] : []
        listdelete.push(code)
        updated_data[index].listdelete = listdelete
        //    console.log(updated_data)
        setdata_map(updated_data)
        calculbrut(updated_data[index])

    }

    const handlechangligne_rub = (e) => {
        const { name, value } = e.target;

        const rubriques = { ...add_rubriques };

        if (name === 'permanent') {
            rubriques.permanent = rubriques.permanent === 1 ? 0 : 1
        }
        else if (name === 'rub_code') {

            const find_same = data_map[click.index].rubs_indemnite.find(item => item.rub_code === value)
            if (find_same) {
                toast.warning(t('cette avantage deja selectionné '))
            } else {
                const findrub = list_rubriques.find(item => item.code === value);
                if (findrub) {

                    rubriques.valeur_mensuel = findrub.plafond === 1 ? findrub.montant_plafond : 0
                    rubriques.valeur_annuel = findrub.plafond === 1 ? findrub.montant_plafond : 0

                }
                rubriques[name] = value

            }
        }
        else if (name === 'date_debut' || name === 'date_fin') {
            rubriques[name] = value

        }
        else {
            rubriques[name] = float_decimale(value) || 0

        }
        setadd_rubriques(rubriques)


    }
    const handlechangligne_avantage = (e) => {
        const { name, value } = e.target;

        const addavantage = { ...add_avantage };

        if (name === 'permanent') {
            addavantage.permanent = addavantage.permanent === 1 ? 0 : 1
        }
        else if (name === 'avantage_code') {
            const find_same = data_map[click.index].avantages.find(item => item.avantage_code === value)
            if (find_same) {
                toast.warning(t('cette avantage deja selectionné '))
            } else {
                addavantage[name] = value

            }
        } else if (name === 'date_debut' || name === 'date_fin') {
            addavantage[name] = value

        }
        else {
            addavantage[name] = float_decimale(value) || 0

        }
        setadd_avantage(addavantage)


    }

    const handlechangligne_retenue = (e) => {
        const { name, value } = e.target;

        const rub_retenue = { ...add_retenue };

        if (name === 'permanent') {
            rub_retenue.permanent = rub_retenue.permanent === 1 ? 0 : 1
        }
        else if (name === 'rub_code') {
            const find_same = data_map[click.index].rubs_retenue.find(item => item.rub_code === value)
            if (find_same) {
                toast.warning(t('cette avantage deja selectionné '))
            } else {

                const findrub = list_rubriques.find(item => item.code === value);

                if (findrub) {

                    rub_retenue.valeur_mensuel = findrub.plafond === 1 ? findrub.montant_plafond : 0

                }
                rub_retenue[name] = value

            }
        } else if (name === 'date_debut' || name === 'date_fin') {

            rub_retenue[name] = value

        }
        else {
            rub_retenue[name] = float_decimale(value) || 0

        }

        setadd_retenue(rub_retenue)


    }
    const handlechangligne = (type, name, index, valeur) => {
        const value = float_decimale(valeur) || 0;

        const copie_data = [...data_map]
        const user = data_map[click.index];
        let list_rub = [...user[type]];
        list_rub[index][name] = value
        copie_data[click.index][type] = list_rub;
        copie_data[click.index].total_rub = copie_data[click.index].rubs_indemnite?.reduce((acc, curr) => {
            const nb_mois = nombreDeMoisEntreDeuxDates(curr.date_debut, curr.date_fin)

            if (curr.presence === 1) {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + ((curr.valeur_annuel / nb_mois) * copie_data[click.index].total);
                } else {
                    return acc + (curr.valeur_mensuel * copie_data[click.index].total);
                }

            } else {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + ((curr.valeur_annuel / nb_mois));
                } else {
                    return acc + (curr.valeur_mensuel);
                }
            };

        }, 0) || 0;

        copie_data[click.index].total_av = copie_data[click.index].avantages?.reduce((acc, curr) => {
            const nb_mois = nombreDeMoisEntreDeuxDates(curr.date_debut, curr.date_fin)

            if (curr.presence === 1) {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + ((curr.valeur_annuel / nb_mois) * copie_data[click.index].total);
                } else {
                    return acc + (curr.valeur_mensuel * copie_data[click.index].total);
                }

            } else {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + ((curr.valeur_annuel / nb_mois));
                } else {
                    return acc + (curr.valeur_mensuel);
                }
            };

        }, 0) || 0;

        copie_data[click.index].commission = copie_data[click.index].total_av + copie_data[click.index].total_rub
        copie_data[click.index].total_avantage_fiscaux = copie_data[click.index].avantage_fiscaux?.reduce((acc, curr) => {

            if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                return acc + curr.valeur_annuel;
            } else {
                return acc + (curr.valeur_mensuel * copie_data[click.index].nb_s);
            }
        }, 0);
        copie_data[click.index].total_retenue = copie_data[click.index].rubs_retenue?.reduce((acc, curr) => {
            const nb_mois = nombreDeMoisEntreDeuxDates(curr.date_debut, curr.date_fin)

            if (curr.presence === 1) {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + ((curr.valeur_annuel / nb_mois) * copie_data[click.index].total);
                } else {
                    return acc + (curr.valeur_mensuel * copie_data[click.index].total);
                }

            } else {
                if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                    return acc + ((curr.valeur_annuel / nb_mois));
                } else {
                    return acc + (curr.valeur_mensuel);
                }
            };
        }, 0) || 0;
        setdata_map(copie_data)
        calculbrut(copie_data[click.index])
    }
    const handlechangligne_av_fiscaux = (e) => {
        const { name, value } = e.target;

        const rubriques = { ...add_av_fiscaux };

        if (name === 'permanent') {
            rubriques.permanent = rubriques.permanent === 1 ? 0 : 1
        }
        else if (name === 'rub_code') {

            const find_same = data_map[click.index].avantage_fiscaux.find(item => item.rub_code === value)
            if (find_same) {
                toast.warning(t('cette avantage deja selectionné '))
            } else {
                const findrub = list_rubriques.find(item => item.code === value);
                if (findrub) {

                    rubriques.valeur_annuel = findrub.plafond === 1 ? findrub.montant_plafond : 0
                    rubriques.valeur_mensuel = findrub.plafond === 1 ? findrub.montant_plafond : 0

                }
                rubriques[name] = value

            }
        } else if (name === 'date_debut' || name === 'date_fin') {
            rubriques[name] = value

        }
        else {
            rubriques[name] = float_decimale(value) || 0

        }
        setadd_av_fiscaux(rubriques)



    }
    const save_ligne = () => {
        save([data_map[click.index]])

    }

    return (
        <div className="col-xl-12 col-md-12 col-lg-12">
            <div className="card">
                <div className="card-body ">
                    <div className='row'>
                        {(prime !== null && !prime?.moisplus) ?(
                            <div className='col-3'>
                            <strong >{t('Prime: ')}</strong>
                             <input onChange={(e) => handleChangedata('salaire_base', e.target.value, click.index)} value={data_map[click.index].salaire_base} /> 
                             </div>) :
                            <strong className='col-3'>{t('Salaire de base : ')} {data_map[click.index].salaire_base}</strong>
                        }
                        <strong className='col-3'>{t('Solde conge : ')} {data_map[click.index].soldeconge}</strong>
                        <strong className='col-3 pointer' onClick={() => setshow(true)}>{t("Visualiser et changer l'employe")} </strong>
                        {change && (
                            <>
                                <button className=" btn btn-primary col-1" onClick={save_ligne}>
                                    {t("Enregistrer")}
                                </button>
                                <span className='col-2 pointer' onClick={() => { setchange(false); }} style={{ fontSize: '30px' }}><IoIosCloseCircle /></span></>

                        )}
                    </div>
                    {change ? (
                        <>
                            <div className='row mt-3' >
                                <input type='checkbox' value={regime_horaire} onChange={() => setregime_horaire(!regime_horaire)} /><strong className='col-2 pointer'>{t("Regime horaire")} </strong>
                                {regime_horaire &&
                                    <>
                                        <div className='col-3'>
                                            <label>
                                                {t("Nombre d'heure par jour ")}
                                            </label>
                                            <input type='text' className='col-2' value={data_map[click.index].Nbr_h_jr} onChange={(e) => handleChangedata('Nbr_h_jr', e.target.value, click.index)} />


                                        </div>
                                        <div className='col-3'>
                                            <label>
                                                {t('taux tranche 1')}
                                            </label>
                                            <input type='text' className='col-2' value={data_map[click.index].h_sup_t1} onChange={(e) => handleChangedata('h_sup_t1', e.target.value, click.index)} />
                                        </div>
                                        <div className='col-3'>
                                            <label>
                                                {t('taux tranche 2')}
                                            </label>
                                            <input type='text' className='col-2' value={data_map[click.index].h_sup_t2} onChange={(e) => handleChangedata('h_sup_t2', e.target.value, click.index)} />
                                        </div>
                                    </>
                                }
                            </div>

                            <div className="row ">

                                <div className='col-6'>
                                    <h1>+</h1>
                                    <div className='row mb-5'>
                                        <strong className='col-4'>{t('libelle')}</strong>
                                        <strong className='col-2'>{t('Nombre')}</strong>
                                        <strong className='col-2'>{t('Taux')}</strong>
                                        <strong className='col-2'>{t('Valeur')}</strong>


                                    </div>
                                    {regime_horaire ? (
                                        <div className='row'>
                                            <strong className='col-4'>{t("Nombre d'heure present ")}</strong>
                                            <input type='text' className='col-1' value={data_map[click.index].total_present * data_map[click.index].Nbr_h_jr} onChange={(e) => handleChangedata('total_heure_present', e.target.value, click.index)} />
                                            <p className='col-1' style={{ color: 'red' }}></p>
                                            <p className='col-2'>{(data_map[click.index].salaire_heure).toFixed(3)}</p>
                                            <p className='col-2'>{data_map[click.index].salaire_p}</p>

                                        </div>
                                    ) :
                                        <div className='row'>
                                            <strong className='col-4'>{t('Nombre de jour present ')}</strong>
                                            <input type='text' className='col-1' value={data_map[click.index].total_present} onChange={(e) => handleChangedata('total_present', e.target.value, click.index)} />
                                            <p className='col-1' style={{ color: 'red' }}>{data_change.find(item => item.userid === data_map[click.index].user_id && item.name === 'total_present')?.oldvalue}</p>
                                            <p className='col-2'>{data_map[click.index].salaire_journee.toFixed(3)}</p>
                                            <p className='col-2'>{data_map[click.index].salaire_p}</p>

                                        </div>}
                                    {regime_horaire &&
                                        <><div className='row'>
                                            <strong className='col-4'>{t("Nombre d'heure supplémentaire tranche 1 ")}</strong>
                                            <input type='text' className='col-1' value={data_map[click.index].heure_supp_t1} onChange={(e) => handleChangedata('heure_supp_t1', e.target.value, click.index)} />
                                            <p className='col-1'></p>
                                            <p className='col-2'>{data_map[click.index].salaire_heure_t1.toFixed(3)}</p>
                                            <p className='col-2'>{data_map[click.index].total_supp_t1}</p>
                                            <strong className='col-2'> {t('soumis retenue')} <input type='checkbox' checked={data_map[click.index].retenu_salaire_hsup1} onChange={(e) => handleChangedata('retenu_salaire_hsup1', e.target.value, click.index)} /></strong>


                                        </div><div className='row'>
                                                <strong className='col-4'>{t("Nombre d'heure supplémentaire tranche2")}</strong>
                                                <input type='text' className='col-1' value={data_map[click.index].heure_supp_t2} onChange={(e) => handleChangedata('heure_supp_t2', e.target.value, click.index)} />
                                                <p className='col-1'></p>
                                                <p className='col-2'>{data_map[click.index].salaire_heure_t2.toFixed(3)}</p>
                                                <p className='col-2'>{data_map[click.index].total_supp_t2}</p>
                                                <strong className='col-2'> {t('soumis retenue')} <input type='checkbox' checked={data_map[click.index].retenu_salaire_hsup2} onChange={(e) => handleChangedata('retenu_salaire_hsup2', e.target.value, click.index)} /></strong>

                                            </div></>
                                    }

                                    <div className='row'>
                                        <strong className='col-4'>{t('Nombre de jour congé payé ')}</strong>
                                        <input type='text' className='col-1' value={data_map[click.index].total_conge} onChange={(e) => handleChangedata('total_conge', e.target.value, click.index)} />
                                        <p className='col-1' style={{ color: 'red' }}>{data_change.find(item => item.userid === data_map[click.index].user_id && item.name === 'total_conge')?.oldvalue}</p>
                                        <p className='col-2'>{data_map[click.index].salaire_journee.toFixed(3)}</p>
                                        <p className='col-2'>{data_map[click.index].salaire_c}</p>

                                    </div>
                                    <div className='row'>
                                        <strong className='col-4'>{t('Nombre de jour ferier ')}</strong>
                                        <p className='col-2'>{data_map[click.index].total_jr_ferire}</p>
                                        <p className='col-2'>{data_map[click.index].salaire_journee.toFixed(3)}</p>
                                        <p className='col-2'>{data_map[click.index].salaire_f}</p>

                                    </div>



                                    <div className='row'>
                                        <strong className='col-4'>{t('Salaire de base de ce mois')}</strong>
                                        <p className='col-2'>{data_map[click.index].total}</p>
                                        <p className='col-2'>{data_map[click.index].salaire_journee.toFixed(3)}</p>

                                        <p className='col-2'>{data_map[click.index].salaire_base_calculer}</p>
                                    </div>


                                    {data_map[click.index].rubs_indemnite && data_map[click.index].rubs_indemnite.length !== 0 ? (
                                        <div className='row mt-5'>
                                            <strong className='col-4'>{t('Les indemnites')}</strong>
                                            <strong className='col-2'>{t('Nbj jour')}</strong>
                                            <strong className='col-1'>{t('V. M')}</strong>
                                            <strong className='col-1'>{t('V. A')}</strong>

                                            <strong className='col-2'>{t('total')}</strong>
                                        </div>) : <strong>{t('Ajouter Indemnites')}</strong>}
                                    {data_map[click.index].rubs_indemnite && data_map[click.index].length !== 0 &&
                                        data_map[click.index].rubs_indemnite?.map((item, i) => (
                                            <div key={i} className='row'>
                                                <p className='col-4' >{t(item.rub_code)}<p style={{ color: 'red' }}>{item.cnss === 1 ? '  soumis cnss' : '  non soumis cnss'}{'-'}{item.irpp === 1 ? '  soumis irpp' : '  non soumis irpp'}</p></p>
                                                <p className='col-2'>{item.presence === 1 ? data_map[click.index].total : null}</p>
                                                <input type="text" style={{ height: '40px' }} onKeyPress={handleKeyPress} className='col-1' value={item.valeur_mensuel} onChange={(e) => handlechangligne('rubs_indemnite', 'valeur_mensuel', i, e.target.value)} />
                                                <input type="text" style={{ height: '40px' }} onKeyPress={handleKeyPress} className='col-1' value={item.valeur_annuel} onChange={(e) => handlechangligne('rubs_indemnite', 'valeur_annuel', i, e.target.value)} />

                                                <p className='col-2'>{calcul_total_rub(item.valeur_mensuel, item.presence, data_map[click.index].total, item.valeur_annuel, nombreDeMoisEntreDeuxDates(item.date_debut, item.date_fin))}</p>

                                                <p className='col-2'><a to="#" className="text-danger font-18" title="remove" onClick={() => handleremove('rub', click.index, item.rub_code)}>
                                                    <i className="fa fa-minus" />
                                                </a></p>
                                            </div>


                                        ))}

                                    <div className="row mt-2">
                                        <select className="form-control col-4" value={add_rubriques.rub_code} onChange={(e) => handlechangligne_rub(e)} name='rub_code'>
                                            <option>{t('choisir ')}</option>
                                            {list_rubriques.filter(item => item.type === 'indemnite').map(item => (
                                                <option key={item.id} value={item.code}>
                                                    {item.libelle}
                                                </option>
                                            ))}
                                        </select>

                                        <input type="text" placeholder="valeur de rubrique" onKeyPress={handleKeyPress} className='col-1 ml-5' name='valeur_mensuel' value={add_rubriques.valeur_mensuel} onChange={(e) => handlechangligne_rub(e)} />
                                        <input type="text" placeholder="valeur de rubrique" onKeyPress={handleKeyPress} className='col-1 ml-5' name='valeur_annuel' value={add_rubriques.valeur_annuel} onChange={(e) => handlechangligne_rub(e)} />


                                        <div className='col-2 ml-5'>
                                            <label> {t('permanent')}</label>
                                            <input type="checkbox" name='permanent' checked={add_rubriques.permanent} onChange={(e) => handlechangligne_rub(e)} />

                                        </div>
                                        {add_rubriques.permanent === 0 &&
                                            <>
                                                <input type="date" className='col-2 ml-5' name='date_debut' value={add_rubriques.date_debut} onChange={(e) => handlechangligne_rub(e)} />

                                                <input type="date" className='col-2 ml-5' name='date_fin' value={add_rubriques.date_fin} onChange={(e) => handlechangligne_rub(e)} />

                                            </>}
                                        <div className=" col-2">
                                            <a style={{ fontSize: '20px' }} className='pointer' onClick={() => handleAddrub('indemnite', click.index)}>
                                                <IoSaveOutline />
                                            </a>

                                        </div>
                                    </div>

                                    {data_map[click.index].avantages && data_map[click.index].avantages.length !== 0 ? (
                                        <div className='row mt-5'>
                                            <strong className='col-4'>{t('Les avantages')}</strong>
                                            <strong className='col-2'>{t('Nbj jour')}</strong>
                                            <strong className='col-1'>{t('V. M')}</strong>
                                            <strong className='col-1'>{t('V. A')}</strong>

                                            <strong className='col-2'>{t('total')}</strong>
                                        </div>) : <strong>{t('Ajouter Avantages')}</strong>}
                                    {data_map[click.index].avantages && data_map[click.index].length !== 0 &&
                                        data_map[click.index].avantages.map((item, i) => (
                                            <div key={i} className='row'>
                                                <p className='col-4'>{t(item.libelle)}<p style={{ color: 'red' }}>{item.cnss === 1 ? '  soumis cnss' : '  non soumis cnss'}{'-'}{item.irpp === 1 ? '  soumis irpp' : '  non soumis irpp'}</p></p>
                                                <p className='col-2'>{item.presence === 1 ? data_map[click.index].total : null}</p>
                                                <input type="text" style={{ height: '40px' }} onKeyPress={handleKeyPress} className='col-1' value={item.valeur_mensuel} onChange={(e) => handlechangligne('avantages', 'valeur_mensuel', i, e.target.value)} />
                                                <input type="text" style={{ height: '40px' }} onKeyPress={handleKeyPress} className='col-1' value={item.valeur_annuel} onChange={(e) => handlechangligne('avantages', 'valeur_annuel', i, e.target.value)} />

                                                <p className='col-2'>{calcul_total_rub(item.valeur_mensuel, item.presence, data_map[click.index].total, item.valeur_annuel, nombreDeMoisEntreDeuxDates(item.date_debut, item.date_fin))}</p>

                                                <p className='col-2'><a to="#" className="text-danger font-18" title="remove" onClick={() => handleremove('av', click.index, item.rub_code)}>
                                                    <i className="fa fa-minus" />
                                                </a></p>
                                            </div>
                                        ))}
                                    <div className="row mt-2">
                                        <select className="form-control col-4" name='avantage_code' value={add_avantage.avantage_code} onChange={(e) => handlechangligne_avantage(e)}>
                                            <option value=''>{t('choisir ')}</option>
                                            {list_avantages.map(item => (
                                                <option key={item.id} value={item.code}>
                                                    {item.libelle}
                                                </option>
                                            ))}
                                        </select>
                                        <input type="text" placeholder="valeur d'avantage" className='col-1 ml-5' name='valeur_mensuel' onKeyPress={handleKeyPress} value={add_avantage.valeur_mensuel} onChange={(e) => handlechangligne_avantage(e)} />
                                        <input type="text" placeholder="valeur d'avantage" className='col-1 ml-5' name='valeur_annuel' onKeyPress={handleKeyPress} value={add_avantage.valeur_annuel} onChange={(e) => handlechangligne_avantage(e)} />

                                        <div className='col-2 ml-5'>
                                            <label> {t('permanent')}</label>
                                            <input type="checkbox" name='permanent' checked={add_avantage.permanent} onClick={(e) => handlechangligne_avantage(e)} />

                                        </div>
                                        {add_avantage.permanent === 0 &&
                                            <>
                                                <input type="date" placeholder="date debut" className='col-2 ml-5' name='date_debut' value={add_avantage.date_debut !== null && moment(new Date(add_avantage.date_debut)).format("DD-MM-YYYY")} onChange={(e) => handlechangligne_avantage(e)} />

                                                <input type="date" placeholder="date fin" className='col-2 ml-5' name='date_fin' value={add_avantage.date_fin !== null && moment(new Date(add_avantage.date_fin)).format("DD-MM-YYYY")} onChange={(e) => handlechangligne_avantage(e)} />

                                            </>}
                                        <div className=" col-2">
                                            <a style={{ fontSize: '20px' }} className='pointer' onClick={() => handleAddrub('avantages', click.index)}>
                                                <IoSaveOutline />

                                            </a>

                                        </div>




                                    </div>

                                    {data_map[click.index].avantage_fiscaux && data_map[click.index].avantage_fiscaux.length !== 0 ? (
                                        <div className='row mt-5'>
                                            <strong className='col-4'>{t('Les avantages fiscaux')}</strong>
                                            <strong className='col-2'>{t('Nbj jour')}</strong>
                                            <strong className='col-1'>{t('V. M')}</strong>
                                            <strong className='col-1'>{t('V. A')}</strong>

                                            <strong className='col-2'>{t('total')}</strong>
                                        </div>) : <strong>{t('Ajouter Avantage fiscaux')}</strong>}
                                    {data_map[click.index].avantage_fiscaux && data_map[click.index].length !== 0 &&
                                        data_map[click.index].avantage_fiscaux?.map((item, i) => (
                                            <div key={i} className='row'>
                                                <p className='col-4' >{t(item.libelle)}</p>
                                                <p className='col-2'>{item.presence === 1 ? data_map[click.index].total : null}</p>
                                                <input type="text" style={{ height: '40px' }} onKeyPress={handleKeyPress} className='col-1' value={item.valeur_mensuel} onChange={(e) => handlechangligne('avantage_fiscaux', 'valeur_mensuel', i, e.target.value)} />
                                                <input type="text" style={{ height: '40px' }} onKeyPress={handleKeyPress} className='col-1' value={item.valeur_annuel} onChange={(e) => handlechangligne('avantage_fiscaux', 'valeur_annuel', i, e.target.value)} />

                                                <p className='col-2'>{item.presence === 1 ? float_decimale(item.valeur_mensuel) * data_map[click.index].total : item.valeur_mensuel}</p>
                                                <p className='col-2'><a to="#" className="text-danger font-18" title="remove" onClick={() => handleremove('av_fiscaux', click.index, item.rub_code)}>
                                                    <i className="fa fa-minus" />
                                                </a></p>
                                            </div>


                                        ))}


                                    <div className="row mt-2">
                                        <select className="form-control col-4" value={add_av_fiscaux.rub_code} onChange={(e) => handlechangligne_av_fiscaux(e)} name='rub_code'>
                                            <option>{t('choisir ')}</option>
                                            {list_rubriques.filter(item => item.type === 'avantage_fiscaux').map(item => (
                                                <option key={item.id} value={item.code}>
                                                    {item.libelle}
                                                </option>
                                            ))}
                                        </select>

                                        <input type="text" placeholder="valeur de rubrique" className='col-1 ml-5' name='valeur_mensuel' value={add_av_fiscaux.valeur_mensuel} onChange={(e) => handlechangligne_av_fiscaux(e)} />
                                        <input type="text" placeholder="valeur de rubrique" className='col-1 ml-5' name='valeur_annuel' value={add_av_fiscaux.valeur_annuel} onChange={(e) => handlechangligne_av_fiscaux(e)} />


                                        <div className='col-2 ml-5'>
                                            <label> {t('permanent')}</label>
                                            <input type="checkbox" name='permanent' checked={add_av_fiscaux.permanent} onChange={(e) => handlechangligne_av_fiscaux(e)} />

                                        </div>
                                        {add_av_fiscaux.permanent === 0 &&
                                            <>
                                                <input type="date" className='col-2 ml-5' name='date_debut' value={add_av_fiscaux.date_debut} onChange={(e) => handlechangligne_av_fiscaux(e)} />

                                                <input type="date" className='col-2 ml-5' name='date_fin' value={add_av_fiscaux.date_fin} onChange={(e) => handlechangligne_av_fiscaux(e)} />

                                            </>}
                                        <div className=" col-2">
                                            <a style={{ fontSize: '20px' }} className='pointer' onClick={() => handleAddrub('av_fiscaux', click.index)}>
                                                <IoSaveOutline />
                                            </a>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>

                                    <h1>-</h1>
                                    {data_map[click.index].rubs_retenue && data_map[click.index].length !== 0 ? (
                                        <div className='row'>
                                            <strong className='col-4'>{t('Les retenues')}</strong>
                                            <strong className='col-2'>{t('Nbj jour')}</strong>
                                            <strong className='col-1'>{t('V. M')}</strong>
                                            <strong className='col-1'>{t('V. A')}</strong>

                                            <strong className='col-2'>{t('total')}</strong>
                                        </div>) : <strong>{t('Ajouter Retenue')}</strong>}

                                    {data_map[click.index].rubs_retenue && data_map[click.index].rubs_retenue.length !== 0 &&
                                        data_map[click.index].rubs_retenue.map((item, i) => (
                                            <div key={i} className='row'>
                                                <p className='col-4'>{t(item.libelle)}</p>
                                                <p className='col-2'>{item.presence === 1 ? data_map[click.index].total : ''}</p>
                                                <input type="text" style={{ height: '40px' }} onKeyPress={handleKeyPress} className='col-1' value={item.valeur_mensuel} onChange={(e) => handlechangligne('rubs_retenue', 'valeur_mensuel', i, e.target.value)} />

                                                <input type="text" style={{ height: '40px' }} onKeyPress={handleKeyPress} className='col-1' value={item.valeur_annuel} onChange={(e) => handlechangligne('rubs_retenue', 'valeur_annuel', i, e.target.value)} />
                                                <p className='col-2'>{calcul_total_rub(item.valeur_mensuel, item.presence, data_map[click.index].total, item.valeur_annuel, nombreDeMoisEntreDeuxDates(item.date_debut, item.date_fin))}</p>


                                                <p className='col-2'><a to="#" className="text-danger font-18" title="remove" onClick={() => handleremove('retenue', click.index, item.rub_code)}>
                                                    <i className="fa fa-minus" />
                                                </a></p>
                                            </div>
                                        ))}
                                    <div className="row mt-5">
                                        <select className="form-control col-4" value={add_retenue.rub_code} onChange={(e) => handlechangligne_retenue(e)} name='rub_code'>
                                            <option>{t('choisir ')}</option>
                                            {list_rubriques.filter(item => item.type === 'retenue').map(item => (
                                                <option key={item.id} value={item.code}>
                                                    {item.libelle}
                                                </option>
                                            ))}
                                        </select>

                                        <input type="text" placeholder="valeur_mensuel" className='col-1 ml-5' name='valeur_mensuel' onKeyPress={handleKeyPress} value={add_retenue.valeur_mensuel} onChange={(e) => handlechangligne_retenue(e)} />
                                        <input type="text" placeholder="valeur_annuel" className='col-1 ml-5' name='valeur_annuel' onKeyPress={handleKeyPress} value={add_retenue.valeur_annuel} onChange={(e) => handlechangligne_retenue(e)} />                                        <div className='col-2 ml-5'>
                                            <label> {t('permanent')}</label>
                                            <input type="checkbox" name='permanent' checked={add_retenue.permanent} onChange={(e) => handlechangligne_retenue(e)} />

                                        </div>

                                        {add_retenue.permanent === 0 &&
                                            <>
                                                <input type="date" className='col-2 ml-5' name='date_debut' value={add_retenue.date_debut} onChange={(e) => handlechangligne_retenue(e)} />

                                                <input type="date" className='col-2 ml-5' name='date_fin' value={add_retenue.date_fin} onChange={(e) => handlechangligne_retenue(e)} />

                                            </>}
                                        <div className=" col-2">

                                            <a style={{ fontSize: '20px' }} className='pointer' onClick={() => handleAddrub('retenue', click.index)}>
                                                <IoSaveOutline />
                                            </a>



                                        </div>
                                    </div>

                                </div>
                            </div></>
                    ) :
                        <>
                            <div className=' row   float-right pointer'><p className='ml-2' style={{ fontSize: '50px' }} onClick={() => setclick({ clicked: false })}><IoIosCloseCircle />
                            </p></div>
                            <div className="row ">

                                <div className='col-6'>
                                    <h1>+</h1>

                                    <div className='row mb-5'>
                                        <strong className='col-4'>{t('libelle')}</strong>
                                        <strong className='col-2'>{t('Nombre')}</strong>
                                        <strong className='col-2'>{t('Taux')}</strong>
                                        <strong className='col-2'>{t('Valeur')}</strong>

                                    </div>

                                    <div className='row'>
                                        <strong className='col-4'>{t('Nombre de jour present ')}</strong>
                                        <p className='col-2'>{data_map[click.index].total_present}</p>
                                        <p className='col-2'>{data_map[click.index].salaire_journee.toFixed(3)}</p>
                                        <p className='col-2'>{data_map[click.index].salaire_p}</p>

                                    </div>
                                    {(data_map[click.index].heure_supp_t2 !== 0 || data_map[click.index].heure_supp_t1 !== 0) &&
                                        <><div className='row'>
                                            <strong className='col-4'>{t("Nombre d'heure supplémentaire tranche 1 ")}</strong>
                                            <p className='col-2'>{data_map[click.index].heure_supp_t1}</p>
                                            <p className='col-2'>{data_map[click.index].salaire_heure_t1.toFixed(3)}</p>
                                            <p className='col-2'>{data_map[click.index].total_supp_t1}</p>

                                        </div><div className='row'>
                                                <strong className='col-4'>{t("Nombre d'heure supplémentaire tranche2")}</strong>
                                                <p className='col-2' >{data_map[click.index].heure_supp_t2}</p>
                                                <p className='col-2'>{data_map[click.index].salaire_heure_t2.toFixed(3)}</p>
                                                <p className='col-2'>{data_map[click.index].total_supp_t2}</p>

                                            </div></>
                                    }
                                    <div className='row'>
                                        <strong className='col-4'>{t('Nombre de jour congé payé ')}</strong>
                                        <p className='col-2'>{data_map[click.index].total_conge}</p>
                                        <p className='col-2'>{data_map[click.index].salaire_journee.toFixed(3)}</p>
                                        <p className='col-2'>{data_map[click.index].salaire_c}</p>
                                    </div>
                                    <div className='row'>
                                        <strong className='col-4'>{t('Nombre de jour ferier ')}</strong>
                                        <p className='col-2'>{data_map[click.index].total_jr_ferire}</p>
                                        <p className='col-2'>{data_map[click.index].salaire_journee.toFixed(3)}</p>
                                        <p className='col-2'>{data_map[click.index].salaire_f}</p>
                                    </div>


                                    <div className='row'>
                                        <strong className='col-4'>{t('salaire de base de ce mois')}</strong>
                                        <p className='col-2'>{data_map[click.index].total}</p>
                                        <p className='col-2'>{data_map[click.index].salaire_journee.toFixed(3)}</p>

                                        <p className='col-2'>{data_map[click.index].salaire_base_calculer}</p>
                                    </div>

                                    <div className='row'>
                                        <strong className='col-3'>{t('Les indemnites ')}</strong>
                                        <strong className='col-1'>{t('Nbj jour')}</strong>
                                        <strong className='col-2'>{t('V. M')}</strong>
                                        <strong className='col-2'>{t('V. A')}</strong>

                                        <strong className='col-2'>{t('total')}</strong>
                                    </div>
                                    {data_map[click.index].rubs_indemnite && data_map[click.index].length !== 0 &&
                                        data_map[click.index].rubs_indemnite.map((item, index) => (
                                            <div key={index} className='row'>
                                                <p className='col-3'>{t(item.libelle)}<p style={{ color: 'red' }}>{item.cnss === 1 ? '  soumis cnss' : '  non soumis cnss'}{'-'}{item.irpp === 1 ? '  soumis irpp' : '  non soumis irpp'}</p></p>
                                                <p className='col-1'>{item.presence === 1 ? data_map[click.index].total : null}</p>
                                                <p className='col-2'>{item.valeur_mensuel}</p>
                                                <p className='col-2'>{item.valeur_annuel}</p>

                                                <p className='col-2'>{calcul_total_rub(item.valeur_mensuel, item.presence, data_map[click.index].total, item.valeur_annuel, nombreDeMoisEntreDeuxDates(item.date_debut, item.date_fin))}</p>
                                            </div>
                                        ))}

                                    <div className='row'>
                                        <strong className='col-3'>{t('Les avantages fiscaux ')}</strong>
                                        <strong className='col-1'></strong>
                                        <strong className='col-2'>{t('V. M')}</strong>
                                        <strong className='col-2'>{t('V. A')}</strong>

                                        <strong className='col-2'>{t('total')}</strong>
                                    </div>
                                    {data_map[click.index].avantage_fiscaux && data_map[click.index].length !== 0 &&
                                        data_map[click.index].avantage_fiscaux.map((item, index) => (
                                            <div key={index} className='row'>
                                                <p className='col-3'>{t(item.libelle)}<p style={{ color: 'red' }}>{item.cnss === 1 ? '  soumis cnss' : '  non soumis cnss'}{'-'}{item.irpp === 1 ? '  soumis irpp' : '  non soumis irpp'}</p></p>
                                                <p className='col-1'></p>
                                                <p className='col-2'>{item.valeur_mensuel}</p>
                                                <p className='col-2'>{item.valeur_annuel}</p>

                                                <p className='col-2'>{item.valeur_annuel ? item.valeur_mensuel * item.nb_s : item.valeur_annuel}</p>
                                            </div>
                                        ))}

                                    <div className='row'>
                                        <strong className='col-3'>{t('Les avantages ')}</strong>
                                        <strong className='col-1'>{t('Nbj jour')}</strong>
                                        <strong className='col-2'>{t('V. M')}</strong>
                                        <strong className='col-2'>{t('V. A')}</strong>

                                        <strong className='col-2'>{t('total')}</strong>
                                    </div>
                                    {data_map[click.index].avantages && data_map[click.index].length !== 0 &&
                                        data_map[click.index].avantages.map((item, index) => (
                                            <div key={index} className='row'>
                                                <p className='col-3'>{t(item.libelle)}<p style={{ color: 'red' }}>{item.cnss === 1 ? '  soumis cnss' : '  non soumis cnss'}{'-'}{item.irpp === 1 ? '  soumis irpp' : '  non soumis irpp'}</p></p>
                                                <p className='col-1'>{item.presence === 1 ? data_map[click.index].total : null}</p>
                                                <p className='col-2'>{item.valeur_mensuel}</p>
                                                <p className='col-2'>{item.valeur_annuel}</p>

                                                <p className='col-2'>{calcul_total_rub(item.valeur_mensuel, item.presence, data_map[click.index].total, item.valeur_annuel, nombreDeMoisEntreDeuxDates(item.date_debut, item.date_fin))}</p>
                                            </div>
                                        ))}

                                </div>
                                <div className='col-6'>

                                    <h1>-</h1>

                                    <div className='row'>
                                        <strong className='col-2'>{t('Les retenues')}</strong>
                                        <strong className='col-1'>{t('Nbj jour')}</strong>
                                        <strong className='col-2'>{t('V. M')}</strong>
                                        <strong className='col-2'>{t('V. A')}</strong>

                                        <strong className='col-2'>{t('total')}</strong>
                                    </div>
                                    {data_map[click.index].rubs_retenue && data_map[click.index].length !== 0 &&
                                        data_map[click.index].rubs_retenue.map((item, index) => (
                                            <div key={index} className='row'>
                                                <p className='col-2'>{t(item.libelle)}<p style={{ color: 'red' }}>{item.cnss === 1 ? '  soumis cnss' : '  non soumis cnss'}{'-'}{item.irpp === 1 ? '  soumis irpp' : '  non soumis irpp'}</p></p>
                                                <p className='col-1'>{item.presence === 1 ? data_map[click.index].total : null}</p>
                                                <p className='col-2'>{item.valeur_mensuel}</p>
                                                <p className='col-2'>{item.valeur_annuel}</p>

                                                <p className='col-2'>{calcul_total_rub(item.valeur_mensuel, item.presence, data_map[click.index].total, item.valeur_annuel, nombreDeMoisEntreDeuxDates(item.date_debut, item.date_fin))}</p>
                                            </div>
                                        ))}

                                </div>
                            </div></>}


                </div>

            </div>
        </div>
    )
}


