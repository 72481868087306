import React, { useEffect, useState } from 'react'
import { getExercice } from '../../Services/Pointeuse/EntrepriseApi';
import { t } from "i18next";
import { Generedeclaration, getdeclaration, getuserdeclaration } from '../../Services/DeclarationCnss/DeclarationCnssApi';
import { toast } from 'react-toastify';
import ImageComponent from '../Styles/ImageComponent';
import { filterUserByWord } from '../../Utils/SortObject';
import _ from 'lodash';

export default function DeclarationCnss() {
  const [exercice_selectionne, setexercice_selectionne] = useState();
  const [exercice, setExercice] = useState([]);
  const [dateP, setdateP] = useState()
  const [trimestreactuel, settrimestreactuel] = useState("3")
  const [preparationdeclaration, setpreparationdeclaration] = useState(false)
  const [listuser, setlistuser] = useState([])
  const [user_selected, setuser_selected] = useState([])
  const [data, setdata] = useState([]);
  const [list_declaration, setlistdeclaration] = useState([])
  const [groupedData, setGroupedData] = useState([]);
  const api_url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getExercice().then((res) => {
      if (Array.isArray(res?.data)) {
        setexercice_selectionne(res?.data[0]?.code_exercice)
        setExercice(res.data);
        const date = res.data[0].code_exercice + '-' + '01'
        setdateP(date)
      }

    });
    getdeclaration().then((res) => {
      if (Array.isArray(res?.data.data)) {
        setlistdeclaration(res.data.data)
        const groupedExercice = _.groupBy(res.data.data, 'exercice');

        setGroupedData(groupedExercice);
      }

    });
  }, []);
  useEffect(() => {
    const listdeclaration = _.orderBy(groupedData[exercice_selectionne], 'trimestre', 'desc');
    const lastdeclaration = listdeclaration[0]
    if(listdeclaration[0])
    settrimestreactuel((parseInt(lastdeclaration?.trimestre) + 1).toString())

  }, [groupedData, exercice_selectionne])

  const DeclarationCnss = async () => {
    const resp = await Generedeclaration(trimestreactuel, exercice_selectionne,user_selected )
    if (resp.status === 200) {
      const copie = [...list_declaration, resp.data.data]
      setlistdeclaration(copie)
      const groupedExercice = _.groupBy(copie, 'exercice');
      setGroupedData(groupedExercice);
      setuser_selected([])
      setpreparationdeclaration(false)


    } else {
      toast.error(resp.message)
    }
  }
  const getData = async () => {
    const resp = await getuserdeclaration(trimestreactuel, exercice_selectionne)
    if (resp.status === 200) {
      if (resp.data.data.length === 0) {
        toast.warning('aucun utilisateur a déclaré ')
      } else {
        setdata(resp.data.data);

        setlistuser(resp.data.data)
        setpreparationdeclaration(true)

      }
    } else {
      toast.error(resp.message)
    }
  }
  const show_file = (Nomfile) => {
    const newTab = window.open(`${api_url}DeclarationFichiers/${Nomfile}`, '_blank');
    if (newTab) {
      // If the new tab was successfully opened, you can focus on it (optional)
      newTab.focus();
    } else {
      toast.error('Popup blocker prevented opening a new tab.');
    }
  };
  const downloadFileFromUrl = async (Nomfile) => {
    try {
      const url = `${api_url}DeclarationFichiers/${Nomfile}`;
      const response = await fetch(url);
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Get the text content (or use response.blob() for binary content)
      const textContent = await response.text(); // Use .blob() if you expect binary data
  
      // Create a Blob from the text content
      const blob = new Blob([textContent], { type: 'text/plain' });
  
      // Create a link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = Nomfile; // Suggests the filename for download
  
      // Append the link to the body
      document.body.appendChild(link);
  
      // Programmatically click the link
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href); // Revoke the object URL to free memory
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  const select_user = (userid) => {
    const findindexuser = user_selected.findIndex(item => item === userid);
    if (findindexuser !== -1) {
      const list = [...user_selected];
      list.splice(findindexuser, 1);
      setuser_selected(list);
    } else {

      setuser_selected((prev) => [...prev, userid])

    }
  }
  const handleSearch = (e) => {
    const dataSearch = filterUserByWord(data, e.target.value);
    setlistuser(dataSearch);
  };
  const selectionall = () => {

    const alldata = data.map(item => item.id)
    setlistuser(data)
    setuser_selected(alldata)
  }
  return (
    <div>
      <h1>{t('Déclaration CNSS')}</h1>
      <div className="card">
        <div className="card-body">
          <div className="col-3 ">
            <select className="form-control" onChange={(e) => setexercice_selectionne(e.target.value)} value={exercice_selectionne} >
              {exercice.map((item, index) => (
                <option value={item.code_exercice} key={index}>
                  {item.code_exercice}
                </option>

              ))}
            </select>

          </div>
          <table className="mt-5 mb-5 table  table-vcenter text-nowrap table-bordered border-top border-bottom" id="hr-attendance">
            <tbody>
              {groupedData[exercice_selectionne] && groupedData[exercice_selectionne].length !== 0 && (groupedData[exercice_selectionne]?.map((item, index) => (
                <tr>
                  <td>Trimestre : {item.trimestre}</td>
                  <td className="pointer link" onClick={() => downloadFileFromUrl(item.dec_salaire_file)}>{t('Support magnétique')}</td>
                  <td className="pointer link" onClick={() => show_file(item.etat_recapitulatif)}>{t('Etat Récapitulatif ')}</td>
                  <td className="pointer link" onClick={() => show_file(item.dec_salaire_pdf)}>{t('Declaration trimestirelle  ')}</td>

                </tr>


              )))}
            </tbody>
          </table>
          {trimestreactuel !== '5' ? (<button
            className="btn btn-primary float-right"
            onClick={() => {
              if (preparationdeclaration) {
                setpreparationdeclaration(false);
                setuser_selected([]);
              } else {
                getData();
              }
            }}
            
          >
            <i className="far fa-credit-card" /> {preparationdeclaration ? 'fermer' : `Generer declaration du ${trimestreactuel} trimestre`}
          </button>) : null}
          {preparationdeclaration &&
            <div className='mt-5'>
              <div className='row mb-3'>
                <h3 className='col-3'>Declaration du {trimestreactuel} {trimestreactuel === "1" ? 'ére' : "éme"} trimestre : </h3>

                <div className='col-3'>
                  <input
                    type="search"
                    className="form-control"
                    placeholder={t("Recherche...")}
                    aria-controls="hr-table"
                    onChange={handleSearch} />
                </div>
                <div className='col-1'>
                  {user_selected.length === 0 ? (
                    <p className="pointer link" onClick={selectionall}>
                      {t('Sélection tout')}
                    </p>
                  ) : (
                    <p className="pointer link" onClick={() => setuser_selected([])}>
                      {t('Désélectionné')}
                    </p>
                  )}
                </div>

                <div className='col-3'>
                  {user_selected.length > 0 && (
                    <button className="btn btn-primary" onClick={DeclarationCnss}>Generer Declaration </button>
                  )}
                </div>
              </div>
              {
                listuser.map((item) => (
                  <div className="d-flex" key={item.id}>
                    <input
                      type="checkbox"
                      onClick={() => select_user(item.id)}
                      checked={user_selected.includes(item.id)} />
                    <ImageComponent atr="avatar avatar-md brround mr-3" picture={item.img} />
                    <div className="mr-3 mt-0 mt-sm-2 d-block">
                      <h6 className="mb-1 fs-14">
                        {item.nom} {item.prenom}
                      </h6>
                    </div>
                  </div>
                ))}
            </div>}




        </div>
      </div>


    </div>
  )
}
